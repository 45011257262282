import React from "react";
import { Link } from 'react-router-dom';
import './styles.scss';
import AuthWrapper from './../AuthWrapper';
import Button from './../forms/Button';
import Button2 from './../forms/Button2';

const Zakka = props => {
    return (
        <AuthWrapper>
                <h3>
                    現在雑貨のお取り扱いがございません。近日入荷予定ですのでお楽しみに！
                </h3>
                <Link to="/search">
                    <Button>
                        アパレル商品を見る
                    </Button>
                </Link>
        </AuthWrapper>
    );
};

export default Zakka;