import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductStart, setProduct, setProductPrice, setProductSelectedIndex } from './../../redux/Products/products.actions';
import { addProduct } from './../../redux/Cart/cart.actions';
import Button from './../forms/Button';
import './styles.scss';
import FormSelect from './../forms/FormSelect';
import { fetchProductsStart } from './../../redux/Products/products.actions';

const mapState = state => ({
  product: state.productsData.product,
  price: state.productsData.price,
  selectedIndex: state.productsData.selectedIndex
});


const ProductCard = ({}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { productID } = useParams();
  const { product, price, selectedIndex } = useSelector(mapState);

  const {
    productThumbnail,
    productName,
    productPrice,
    productDesc,
    productVariation,
    index
  } = product;

  useEffect(() => {
    dispatch(
      fetchProductStart(productID)
    )

    return () => {
      dispatch(
        setProduct({})
      )
    }

  }, []);

  const handleAddToCart = (product, selectedIndex) => {
    if (!product) return;
    product.index = selectedIndex;
    dispatch(
      addProduct(product,　selectedIndex)
    );
    history.push('/cart');
  }

  const configAddToCartBtn = {
    type: 'button'
  }

  const { filterType } = useParams();
  useEffect(() => {
    dispatch(
      fetchProductsStart({ filterType })
    )
  }, [filterType]);

  const handleFilter = (e) => {
    dispatch(setProductSelectedIndex(e.target.selectedIndex));
    dispatch(setProductPrice(parseInt(e.target.value)));
  };

  const configFilters = {
    defaultValue: filterType,
    options: productVariation,
    handleChange: handleFilter
  };

  return (
    <div className="productCard">
      <div className="hero">
        <img src={productThumbnail} />
      </div>
      <div className="productDetails">
        <ul>
          <li>
            <h1>
              {productName}
            </h1>
          </li>
          <li>
            <span className="priceTag">
              {price}円
            </span>
          </li>
          <FormSelect {...configFilters} />
          <li>
            <div className="addToCart">
              <Button {...configAddToCartBtn} onClick={() => handleAddToCart(product,selectedIndex)}>
                追加する
              </Button>
            </div>
          </li>
          <li>
            <span
              className="desc"
              dangerouslySetInnerHTML={{ __html: productDesc }} />
          </li>
        </ul>
      </div>
    </div>
  );
}

export default ProductCard;
