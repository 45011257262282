import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const Loading = ({ isLoading }) => (
  <div>
    {isLoading && (
      <div className="loadingBox">
        <div className="boxInner">
          <div className="boxBg">
            <CircularProgress />
          </div>
        </div>
      </div>
    )}
  </div>
);

export default Loading;