import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { checkUserSession } from './redux/User/user.actions';

// components
import AdminToolbar from './components/AdminToolbar';
import Loading from './components/Loading';

// hoc
import WithAuth from './hoc/withAuth';
import WithAdminAuth from './hoc/withAdminAuth';

// layouts
import MainLayout from './layouts/MainLayout';
import HomepageLayout from './layouts/HomepageLayout';
import AdminLayout from './layouts/AdminLayout';
import DashboardLayout from './layouts/DashboardLayout';

// pages
import Homepage from './pages/Homepage';
import Search from './pages/Search';
import Zakka from './components/Zakka';
import Registration from './pages/Registration';
import Login from './pages/Login';
import Recovery from './pages/Recovery';
import Dashboard from './pages/Dashboard';
import Admin from './pages/Admin';
import ProductDetails from './pages/ProductDetails';
import Cart from './pages/Cart';
import Payment from './pages/Payment';
import Order from './pages/Order';
import './default.scss';
import { useSelector } from 'react-redux';

const mapState = ({ common }) => ({
  isLoading: common.isLoading
})

const App = props => {
  const dispatch = useDispatch();
  const { isLoading } = useSelector(mapState);

  useEffect(() => {
    dispatch(checkUserSession());

  }, []);

  return (
    <div className="App">
      <Loading isLoading={isLoading} />
      <AdminToolbar />
      <Switch>
        <Route exact path="/" render={() => (
          <WithAuth>
            <HomepageLayout>
              <Homepage />
            </HomepageLayout>
          </WithAuth>
        )}
        />
        <Route exact path="/search" render={() => (
          <WithAuth>
            <MainLayout>
              <Search />
            </MainLayout>
          </WithAuth>
        )} />
        <Route exact path="/zakka" render={() => (
          <WithAuth>
            <MainLayout>
              <Zakka />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/search/:filterType" render={() => (
          <WithAuth>
            <MainLayout>
              <Search />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/product/:productID" render={() => (
          <WithAuth>
            <MainLayout>
              <ProductDetails />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/cart" render={() => (
          <WithAuth>
            <MainLayout>
              <Cart />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/payment" render={() => (
          <WithAuth>
            <MainLayout>
              <Payment />
            </MainLayout>
          </WithAuth>
        )} />
        <Route path="/registration" render={() => (
            <MainLayout>
              <Registration />
            </MainLayout>
        )} />
        <Route path="/login"
          render={() => (
            <MainLayout>
              <Login />
            </MainLayout>
          )} />
        <Route path="/recovery" render={() => (
          <MainLayout>
            <Recovery />
          </MainLayout>
        )} />
        <Route path="/dashboard" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Dashboard />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/order/:orderID" render={() => (
          <WithAuth>
            <DashboardLayout>
              <Order />
            </DashboardLayout>
          </WithAuth>
        )} />
        <Route path="/admin" render={() => (
          <WithAdminAuth>
            <AdminLayout>
              <Admin />
            </AdminLayout>
          </WithAdminAuth>
        )} />
      </Switch>
    </div>
  );
}

export default App;
