import * as commonActions from './common.actions';

const initialState = {
  isLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case commonActions.SHOW_LOADING:
      return { ...state, ...{ isLoading: true } };
    case commonActions.HIDE_LOADING:
      return { ...state, ...{ isLoading: false } };
    default:
      return state;
  }
};