import React from "react";
import { Link } from 'react-router-dom';
import Clothes from './../../assets/clothes.jpg';
import Items from './../../assets/items.jpg';
import './styles.scss';

const Directory = props => {
    return (
        <div className="directory">
            <div className="wrap">
                <div
                    className="item"
                    style={{
                        backgroundImage: `url(${Clothes})`
                    }}
                >
                <Link to="/search">
                <a class="btn-solid-lg">アパレル</a>
                </Link>
                </div>
                <div
                    className="item"
                    style={{
                        backgroundImage: `url(${Items})`
                    }}
                >
                <Link to="/zakka">
                <a class="btn-solid-lg">雑貨</a>
                </Link>
                </div>
            </div>
        </div>
    );
};

export default Directory;